import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbAuthService, NbAuthJWTToken, NbTokenService } from '@nebular/auth';
import { NbMediaBreakpointsService, NbMenuItem, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { Router } from '@angular/router';

import { HttpClient } from '@angular/common/http';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { filter, map, takeUntil, takeWhile } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  // user: any;
  user: any = [{}];
  users_session: any = [{}];
  // user: [{}];

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  // userMenu = [ { title: 'Profile' }, { title: 'Log out' } ];
  userMenu = [ { title: 'Вийти', data: { id: 'logout' } } ];

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private userService: UserData,
              private layoutService: LayoutService,
              private breakpointService: NbMediaBreakpointsService,
              private authService: NbAuthService,
              private nbTokenService:NbTokenService,
              private httpClient: HttpClient,
              public router: Router) {

              this.authService.onTokenChange()
                .subscribe((token: NbAuthJWTToken) => {
                  if (token.isValid()) {
                    // console.log(token.getPayload());
                    this.user.id_sql = token.getPayload().id_sql;
                    this.user.id = token.getPayload().id;
                    this.user.name = token.getPayload().name; // here we receive a payload from the token and assigns it to our `user` variable
                    this.user.company = token.getPayload().company;
                    this.user.whois = token.getPayload().name+' ('+token.getPayload().company+')';
                  }
              });
  }

//  constructor(private authService: NbAuthService) {
//    this.authService.onTokenChange()
//      .subscribe((token: NbAuthJWTToken) => {
//        if (token.isValid()) {
//          this.user = token.getPayload(); // here we receive a payload from the token and assigns it to our `user` variable
//        }
//      });
//  }


  private alive: boolean = true;

  ngOnInit() {

    this.currentTheme = this.themeService.currentTheme;

    this.menuService.onItemClick().subscribe(( event ) => {
      // this.onItemSelection(event.item.title);
      // console.log(event.item.title);
      if (event.item.title == "Вийти") {
        // console.log(this.user.id);
        this.nbTokenService.clear();
        this.router.navigate(['/auth/logout']);
      } else {
          console.log(this.sidebarService.toggle);
      }
    });

    //this.menuService.onItemClick().pipe(takeWhile(() => this.alive)).subscribe( (menuBag) => {
    //    // console.log(menuBag);
    //    if (menuBag.item.data.id == 'logout') {
    //        this.nbTokenService.clear();
    //        this.router.navigate(['/auth/logout']);
    //    }
    //});

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();

    // click on logout
    this.alive = false;
  }

  onItemSelection( title ) {
    if ( title === 'Log out' ) {
      // Do something on Log out
      console.log('Log out Clicked ')
    } else if ( title === 'Profile' ) {
      // Do something on Profile
      console.log('Profile Clicked ')
    }
  }

  //changeTheme(themeName: string) {
  //  this.themeService.changeTheme(themeName);
  //}

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
}
